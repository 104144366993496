import http from './http'
import Config from './config';
const apiHeader = Config.apiUrl
export default {
  // 款号分页模糊查询接口
  styleNoList: (data) => http.post(`${apiHeader}/session/styleNo/pageQueryList`, {data}),
  // 款式编号创建接口
  styleNoCreate: data => http.post(`${apiHeader}/session/styleNo/create`, {data}),
  // 群标签创建接口
  labelCreate: data => http.post(`${apiHeader}/session/label/create`, {data}),
  // 标签模糊查询接口
  labelList: data => http.post(`${apiHeader}/session/label/pageQueryList`, {data}),
}