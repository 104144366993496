<template>
  <page>
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div style="padding: 15px;">
        <div class="f_flex f_a_c f_j_sb underline">
          <input type="text" placeholder="请输入" v-model="styleNum" class="labelNameInput f_g1">
        </div>
        <div style="height: 15px;"></div>

        <button
          @click="createStyleNo"
          style="width: 80%; margin-top: 126px"
          class="btn btn_radius theme_bg margin_auto">
          确定
        </button>
      </div>
    </div>
  </page>
</template>

<script>
import styleNoApi from '@/api/styleNo'
import {mapActions} from 'vuex'

export default {
  data: ()=> ({
    styleNum: '',
  }),
  methods: {
    ...mapActions([
      'set_styleNum'
    ]),
    createStyleNo() {
      const {companyId, postsSource} = this.$route.query
      if (this.styleNum) {
        const obj = {
          styleNoSource: postsSource,
          bizId: companyId,
          styleNo: this.styleNum
        }
        this.$global.debounce(()=> {
          styleNoApi.styleNoCreate(obj).then(res => {
            if (res.data) {
              this.$toast(res.data.msg)
              // {styleId, styleNo} 修改格式保持和分页查询统一
              this.set_styleNum({
                styleId: res.data.bizId,
                styleNo: this.styleNum
              })
              setTimeout(() => {
                this.$router.back()
              }, 20)
            }
          })
        })
      } else {
        this.$toast('输入不能为空')
      }
    }
  }
}
</script>


<style lang="less" scoped>
.labelNameInput {
  border: none;
  outline: none;
  height: 50px;
  font-size: 14px;
}
</style>